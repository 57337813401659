import { Link } from "gatsby"
import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { FaLinkedinIn, FaFacebookF, FaTwitter } from "react-icons/fa"
// import {  FaFacebookSquare, FaSquareXTwitter  } from "react-icons/fa6"
import { BsTwitterX } from "react-icons/bs"

import logo from "../../images/new-images/footer-logo.png"
import quickbase from "../../images/new-images/quickbase-footer.png"
import workato from "../../images/new-images/workato-footer.png"
import pipefy from "../../images/new-images/pipefy-footer.png"
import fb from "../../images/new-images/fb-icon.png"
import linkedin from "../../images/new-images/linkedin-icon.png"
import twitter from "../../images/new-images/twitter-logo.png"

const footer = props => {
  return (
    <>
      <footer
        className="site-footer section"
        style={{
          padding: "3rem 0",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 col-md-4">
              {/* <StaticImage
                placeholder="blurred"
                className="img-fluid"
                src="https://cdn.sanity.io/images/55lcecww/production/ec471b6e5dbc57a0b6661f91d5eb04f41f9ebaed-215x49.webp"
                alt="Quandary logo"
                // width={215}
                layout="constrained"
                loading="lazy"
              /> */}
              <img
                placeholder="blurred"
                className="img-fluid"
                src={logo}
                alt="Quandary logo"
                // width={215}
                layout="constrained"
                loading="lazy"
              />
              <p className="mb-0 mt-3 pt-3">
                6400 S Fiddlers Green Circle
                <br />
                Suite 250
                <br /> Greenwood Village, CO 80111
              </p>
              <p>
                <strong> T: </strong>
                <a
                  href="tel:+17207391406"
                  onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '720-739-1406', 0);"
                >
                  (720) 739-1406
                </a>
                <br />
                <strong> E:</strong>{" "}
                <a href="mailto:info@quandarycg.com">info@quandarycg.com</a>
              </p>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <img
                      placeholder="blurred"
                      className="img-fluid partner-logo"
                      src={quickbase}
                      alt="Gold Partner"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-3">
                    {/* <StaticImage
                      placeholder="blurred"
                      className="img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/9606706b8f6d0228f28cdd280f913ede1e6b4922-1600x667.webp?w=400"
                      alt="Workato Gold"
                      layout="constrained"
                      loading="lazy"
                    /> */}
                    <img
                      placeholder="blurred"
                      className="img-fluid partner-logo"
                      src={workato}
                      alt="Workato Gold"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-3">
                    {/* <StaticImage
                      placeholder="blurred"
                      className="img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/49e5bc6eb088a76d3e9e64f1f61a21ba9f020d6c-1305x318.webp?w=400"
                      alt="Pipefy"
                      layout="constrained"
                      loading="lazy"
                    /> */}
                    <img
                      placeholder="blurred"
                      className="img-fluid partner-logo"
                      src={pipefy}
                      alt="Pipefy"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 col-md-4 mt-lg-0 mt-md-0 mt-5">
              <div className="pl-md-4 mb-5">
                <p className="subheading">Services</p>
                <ul className="list-unstyled">
                  <li>
                    <Link aria-label="link" to="/tech-consulting/">
                      Technology Consulting
                    </Link>
                  </li>
                  <li>
                    <Link aria-label="link" to="/quandary-build/">
                      Application Development
                    </Link>
                  </li>
                  <li className="list-size-tech">
                    <Link aria-label="link" to="/quandary-connect/">
                      Integration Services
                    </Link>
                  </li>
                  <li className="list-size-tech">
                    <Link
                      aria-label="link"
                      to="/procurement-automation-services/"
                    >
                      Procurement Automation
                    </Link>
                  </li>
                  <li className="list-size-tech">
                    <Link
                      aria-label="link"
                      to="/post-launch-enablement-services/"
                    >
                      Post Launch Enablement
                    </Link>
                  </li>
                  <li className="list-size-tech">
                    <Link
                      aria-label="link"
                      to="/offline-application-development-construction-management/"
                    >
                      Construction Management
                    </Link>
                  </li>
                  <li className="list-size-tech">
                    <Link aria-label="link" to="/retail-automation/">
                      Retail Automation
                    </Link>
                  </li>
                  <li className="list-size-tech">
                    <Link aria-label="link" to="/citizen-development-support/">
                      Citizen Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 col-lg-2 col-md-4 mt-lg-0 mt-md-0 my-5">
              <p className="subheading">products</p>
              <ul className="list-unstyled">
                <li>
                  <Link to="/peaksuite/">peakSUITE</Link>
                </li>
                <li>
                  <Link to="/quick-base-solutions/">Quickbase Solutions</Link>
                </li>
                <li>
                  <Link to="/workato-solutions/">Workato Solutions</Link>
                </li>
                <li>
                  <Link to="/pipefy/">Pipefy Solutions</Link>
                </li>

                {/* <li>
                  <Link aria-label='link' to="/quandary-marketing/">Quandary Marketing</Link>
                </li>*/}
              </ul>
            </div>

            <div className="col-6 col-lg-2 col-md-4 ml-lg-0 ml-md-auto">
              <p className="subheading">Resources</p>
              <ul className="list-unstyled">
                {/* <li>
                  <Link to="/write-for-us/">Write For Us</Link>
                </li> */}
                <li>
                  <Link to="/knowledge-base/">Knowledge Base</Link>
                </li>
                <li>
                  <Link to="/news/">Blog Entries</Link>
                </li>
                <li>
                  <Link to="/case-studies/">Case Studies</Link>
                </li>
                <li>
                  <Link to="/faq/">FAQ</Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-lg-2 col-md-4">
              <p className="subheading">Company</p>
              <ul className="list-unstyled">
                <li>
                  <Link to="/about-us/">About Us</Link>
                </li>
                <li>
                  <Link to="/careers/">Careers</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
                <li>
                  <Link to="/our-approach/">Our Approach</Link>
                </li>
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li className="list-inline-item mt-2">
                  <a
                    href="https://www.linkedin.com/company/quandarycg"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#0D65A6",
                      padding: "6px 8px 10px 8px",
                      borderRadius: "0.2rem",
                    }}
                    aria-label="Quandary LinkedIn"
                  >
                    <img
                      placeholder="blurred"
                      className="img-fluid footer-icon"
                      src={linkedin}
                      alt="LinkedIn logo"
                      // width={215}
                      layout="constrained"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li className="list-inline-item mt-2">
                  <a
                    href="https://www.facebook.com/quandaryconsulting/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#0D65A6",
                      padding: "6px 8px 10px 8px",
                      borderRadius: "0.2rem",
                    }}
                    aria-label="Quandary Facebook"
                  >
                    <img
                      placeholder="blurred"
                      className="img-fluid footer-icon"
                      src={fb}
                      alt="Facebook logo"
                      // width={215}
                      layout="constrained"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li className="list-inline-item mt-2">
                  <a
                    href="https://twitter.com/quandarycg"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#0D65A6",
                      padding: "6px 8px 10px 8px",
                      borderRadius: "0.2rem",
                    }}
                    aria-label="Quandary Twitter"
                  >
                    <img
                      placeholder="blurred"
                      className="img-fluid footer-icon"
                      src={twitter}
                      alt="Twitter logo"
                      // width={215}
                      layout="constrained"
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <section id="copyrights" className="py-4">
        <div className="container">
          <div className="row">
            <div className="col text-lg-right text-md-center text-center">
              <p className="mb-0">
                © {new Date().getFullYear()} Quandary Consulting Group. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default footer
